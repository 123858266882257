import { useCallback, useMemo } from 'react';
import { motion } from 'framer-motion';

import { useMenuItemIsActive } from '../../hooks/useMenuItemIsActive';
import { L10nProperties, Language } from '../../types';

import './NavItem.css';
interface NavItemProps {
  language?: Language;
  item: L10nProperties;
  onClick: (path?: string) => void;
}

export function NavItem({
  language,
  item: { label, path, section },
  onClick,
}: NavItemProps) {
  const active = useMenuItemIsActive(section);
  console.log('');
  const handleClick = useCallback(() => {
    if (language) onClick(path[language]);
  }, [path, language, onClick]);
  const parsedLabel = useMemo(() => {
    return language ? label[language] : '';
  }, [label, language]);
  return (
    <motion.li
      key={`${section}${active}`}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '0.05rem 0',
        borderBottom: '0.1rem solid',
        marginTop: '0.1rem',
        marginRight: '20px',
        fontSize: '0.8rem',
        textTransform: 'uppercase',
        listStyleType: 'none',
        cursor: 'pointer',
      }}
      initial={{
        borderBottomColor: active
          ? 'rgba(0, 0, 0, 1)'
          : 'rgba(255, 255, 255, 1)',
      }}
      transition={{
        type: 'tween',
      }}
      whileHover={{
        borderBottomColor: 'rgba(0, 0, 0, 1)',
      }}
      role="link"
      onClick={handleClick}
    >
      {parsedLabel}
    </motion.li>
  );
}
