import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Language, Section } from '../../types';
import { l10n } from '../../settings';

import './LanguagePicker.css';

interface LanguagePickerProps {
  language?: Language;
  items: Language[];
  onSelect: (lang: Language) => void;
}

export function LanguagePicker({
  language,
  items,
  onSelect,
}: LanguagePickerProps) {
  const history = useHistory();
  const location = useLocation();

  const handleClick = React.useCallback(
    (nextLanguage: Language) => {
      const path = Object.keys(l10n).reduce((acc, curr) => {
        const section = curr as Section;
        if (language && l10n[section].path[language] === location.pathname) {
          acc = l10n[section].path[nextLanguage];
        }
        return acc;
      }, '');
      history.push(path);
      onSelect(nextLanguage);
    },
    [history, language, location, onSelect],
  );
  return (
    <ul className="lr-language-picker-list">
      {items.map((item, index) => {
        return (
          <li
            key={index}
            className="lr-language-picker-item"
            onClick={() => handleClick(item)}
          >
            {item}
          </li>
        );
      })}
    </ul>
  );
}
