import React from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

import { getColorBySection } from '../../utils';
import { Section } from '../../types';

import './ContentLayout.css';

interface ContentLayoutProps {
  imageAlt?: string;
  imageSrc?: string;
  invertOrder?: boolean;
  text: React.ReactNode;
  section: Section;
}

export function ContentLayout({
  imageAlt,
  imageSrc,
  invertOrder,
  text,
  section,
}: ContentLayoutProps) {
  const backgroundColor = React.useMemo(() => getColorBySection(section), [
    section,
  ]);
  const { pathname } = useLocation();
  return (
    <div
      className={
        !invertOrder ? 'lr-content-layout' : 'lr-content-layout__inverted'
      }
    >
      <div className="lr-content-layout-image">
        {
          <motion.img
            key={pathname}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { ease: 'easeIn', duration: 0.3 },
            }}
            alt={imageAlt}
            src={imageSrc}
          />
        }
      </div>
      <div className="lr-content-layout-text" style={{ backgroundColor }}>
        <motion.div
          key={pathname}
          style={{ position: 'relative', margin: '2rem' }}
          initial={{ top: '1rem', opacity: 0 }}
          animate={{
            top: 0,
            opacity: 1,
            transition: { ease: 'easeIn', duration: 0.4 },
          }}
        >
          {text}
        </motion.div>
      </div>
    </div>
  );
}
