import React from 'react';

import { Language } from '../../types';

interface YogaContentProps {
  language?: Language;
}

export function YogaContent({ language }: YogaContentProps) {
  switch (language) {
    case Language.German:
      return (
        <>
          <h2>Komme in Deinen Yoga-Flow</h2>
          <p>
            Warum praktizieren immer mehr Menschen Yoga? Als junge Tänzerin
            dachte ich zuerst, dass Yoga mir nichts Neues beibringen kann, aber
            ich lag falsch: Es macht dich nicht nur auf deinen Körper
            aufmerksam, sondern auch auf deinen Atem, deine Gedanken, dein
            inneres Lächeln und dessen Klang und letztendlich dein inneres
            Wesen. Und es macht das Leben reichhaltiger, schöner und
            kraftvoller.
          </p>
          <p>
            Yoga beinhaltet Atemübungen, Körperstellungen und Meditation, um
            Entspannung zu fördern und Stress abzubauen. Es ist eine uralte
            Praxis, die Körper und Geist zusammenbringt und viele Vorteile
            mitbringt. Egal ob du dich entscheidest einmal pro Woche Sport zu
            treiben, um dein Wohlbefinden zu steigern, oder ob du versuchst es
            als tägliche Praxis umzusetzen - Yoga besteht aus guten, gesunden
            Praktiken, die dein Leben bereichern können.
          </p>
          <p>
            In meinen Hatha Yoga Flow Kursen verbessern wir unsere Flexibilität,
            Beweglichkeit und Kraft, aktivieren unsere Lebensenergie und
            Vitalität durch Atemtechniken, schulen besondere Achtsamkeit, um
            Verspannungen zu lösen und laden uns mit tiefer Entspannung und
            kurzer Meditation am Ende der Übungen mit neuer positiver Energie
            auf.
          </p>
        </>
      );
    case Language.Czech:
      return (
        <>
          <h2>V toku jógy</h2>
          <p>
            Proč stále více lidí cvičí jógu? Jako mladá tanečnice jsem si
            nejprve myslela, že mě jóga nemůže naučit ničemu novému, ale mýlila
            jsem se; nejen že si při ní důkladně uvědomíte své tělo, ale také
            váš dech, vaše myšlenky, váš vnitřní úsměv a zvuk, a nakonec vaše
            vnitřní bytí - život se obohatí, zjemní a posílí.
          </p>
          <p>
            Jóga zahrnuje dechová cvičení, pozice a meditace, jejichž účinek
            povzbuzuje relaxaci a snižuje stres. Jedná se o starodávnou praxi,
            která spojuje mysl a tělo a má mnoho výhod. Ať už se rozhodnete
            cvičit jednou týdně pro dobrý pocit, nebo se ji snažíte osvojit jako
            každodenní praxi, je to soubor dobrých a zdravých návyků, které
            mohou obohatit váš život.
          </p>
          <p>
            V mých hodinách Hatha Yoga Flow zlepšujeme flexibilitu, mobilitu a
            sílu, aktivujeme životní energii a vitalitu dýchacími technikami.
            Dále uvolňujeme napětí, cvičíme pozornost, a nabíjíme se novou
            pozitivní energií v hlubší relaxaci a krátké meditaci na konci
            cvičení.
          </p>
        </>
      );
    default:
      return (
        <>
          <h2>In a Yoga Flow</h2>
          <p>
            Why do more and more people practice yoga? As a young dancer I first
            thought that yoga cannot teach me anything new, but I was wrong; it
            not only makes you thoroughly aware of your body, but also of your
            breath, your thoughts, your inner smile and sound, and ultimately
            your inner being – making life richer, finer and stronger.
          </p>
          <p>
            Yoga incorporates breathing exercises, poses and meditation, which
            are designed to encourage relaxation and reduce stress. It is an
            ancient practice that brings together mind and body and has many
            benefits. Whether you decide to exercise once a week for wellness,
            or try to implement it as a daily practice, it is a set of good,
            healthy habits that enrich your life.
          </p>
          <p>
            In my Hatha Yoga Flow classes we improve flexibility, mobility and
            strength, activate life energy and vitality though breathing
            techniques. We train further awareness in letting go of tensions and
            charging with a new positive energy in a deeper relaxation and short
            meditation at the end of a class.
          </p>
        </>
      );
  }
}
