import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Language } from '../types';

export const languages = [Language.German, Language.English, Language.Czech];

export const defaultLanguage = Language.German;

export function useLanguage(): [
  Language,
  React.Dispatch<React.SetStateAction<Language>>,
] {
  const [language, setLanguage] = useState<Language>(defaultLanguage);
  const location = useLocation();
  useEffect(() => {
    const pathArray = location.pathname.split('/');
    if (pathArray.length > 1) {
      const lang = languages.find((l) => l === (pathArray[1] as Language));
      if (lang) setLanguage(lang);
    }
  }, [location.pathname, setLanguage]);
  return [language, setLanguage];
}
