import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';

import { NavItem } from '../NavItem';

import { L10nProperties, Language } from '../../types';

import logo from '../../assets/svg/lr-logo-20.svg';

import './NavList.css';

interface NavListProps {
  language: Language | undefined;
  items: Array<L10nProperties>;
  showLogo?: boolean;
}

export function NavList({ language, items, showLogo }: NavListProps) {
  const history = useHistory();
  const handleLogoClick = useCallback(() => {
    history.push(`/${language}`);
  }, [history, language]);
  const handleNavItemClick = useCallback(
    (path?: string) => {
      if (path) history.push(path);
    },
    [history],
  );
  return (
    <nav className="lr-nav-list">
      <ul>
        {showLogo && (
          <motion.li
            style={{
              height: '1.5rem',
              width: '1.5rem',
              margin: 'auto 20px auto 0',
              listStyleType: 'none',
              transformOrigin: 'center',
              cursor: 'pointer',
            }}
            initial={{
              transform: 'scale(1) rotate(0deg)',
            }}
            whileHover={{
              transform: 'scale(1.1) rotate(60deg)',
            }}
            role="link"
            onClick={handleLogoClick}
          >
            <img src={logo} className="lr-logo" alt="logo" />
          </motion.li>
        )}
        {items.map((item, index) => {
          return (
            <NavItem
              key={index}
              language={language}
              item={item}
              onClick={handleNavItemClick}
            />
          );
        })}
      </ul>
    </nav>
  );
}
