import React from 'react';

import { motion } from 'framer-motion';

import './BodyLayout.css';

interface BodyContainerProps {
  left: React.ReactNode;
  center: React.ReactNode;
  right: React.ReactNode;
}

export function BodyLayout({ left, center, right }: BodyContainerProps) {
  return (
    <div className="lr-body-layout">
      <motion.div className="lr-body-left" initial={{ width: '3rem' }}>
        {left}
      </motion.div>
      <main className="lr-body-center">{center}</main>
      <motion.div className="lr-body-right" initial={{ width: '3rem' }}>
        {right}
      </motion.div>
    </div>
  );
}
