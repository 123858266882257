import React from 'react';

import './AppLayout.css';

interface AppContainerProps {
  header: React.ReactNode;
  body: React.ReactNode;
  footer: React.ReactNode;
}

export function AppLayout({ header, body, footer }: AppContainerProps) {
  return (
    <div className="lr-app-layout">
      <div className="lr-app-layout-header">{header}</div>
      <div className="lr-app-layout-body">{body}</div>
      <div className="lr-app-layout-footer">{footer}</div>
    </div>
  );
}
