import React from 'react';

import './FooterLayout.css';

interface FooterLayoutProps {
  menu: React.ReactNode;
  copyright: React.ReactNode;
}

export function FooterLayout({ copyright, menu }: FooterLayoutProps) {
  return (
    <footer className="lr-footer">
      <div className="lr-footer-menu">{menu}</div>
      <p className="lr-footer-copyright">{copyright}</p>
    </footer>
  );
}
