import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { sectionsByPath } from '../settings';
import { Section } from '../types';

export function useSection() {
  const [section, setSection] = useState<Section>();
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname.split('?')[0];
    if (sectionsByPath[path]) setSection(sectionsByPath[path]);
  }, [location.pathname]);
  return section;
}
