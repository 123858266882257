import React from 'react';
import Obfuscate from 'react-obfuscate';

import { Language } from '../../types';

interface ImpressumContentProps {
  language?: Language;
}

export function ImpressumContent({ language }: ImpressumContentProps) {
  switch (language) {
    case Language.Czech:
      return (
        <>
          <h2>Impressum</h2>
          <p>
            Lucia Ramsonius
            <br />
            1230 Vídeň
          </p>
          <p>
            <em>
              <Obfuscate email="lucia.ramsonius@gmail.com" />
            </em>
          </p>
          <h3>Odmítnutí</h3>
          <p>
            I přes pečlivou kontrolu obsahu nepřebíráme žádnou odpovědnost za
            obsah externích odkazů. Za jejich obsah odpovídají výhradně
            provozovatelé odkazovaných stránek.
          </p>
          <h3>Autorská práva</h3>
          <p>
            Tyto webové stránky slouží k poskytování informací o Lucii Ramsonius
            a jejích službách. Texty, obrázky, grafika a rozvržení stejně jako
            celá grafická podoba těchto stránek jsou chráněny autorskými právy.
          </p>
          <p>
            Celý obsah stránek nesmí být kopírován, distribuován nebo měněn pro
            komerční účely. Stahování a tisk textů, obrázků a grafických prvků
            je povoleno pouze pro osobní, soukromé a nekomerční použití.
            Duplikace grafiky, obrázků, zvukových souborů, videosekvencí a textů
            v jiných elektronických nebo tištěných publikacích je možná pouze s
            písemným souhlasem Lucia Ramsonius.
          </p>
          <p>
            "O mně" Portrét © Ludwig Schedl
          </p>
        </>
      );
    case Language.German:
      return (
        <>
          <h2>Impressum</h2>
          <p>
            Lucia Ramsonius
            <br />
            1230 Wien
          </p>
          <p>
            <em>
              <Obfuscate email="lucia.ramsonius@gmail.com" />
            </em>
          </p>
          <h3>Haftungsausschluss</h3>
          <p>
            Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine
            Haftung für die Inhalte externer Links. Für den Inhalt der
            verlinkten Seiten sind ausschließlich deren Betreiber
            verantwortlich.
          </p>
          <h3>Urheberrecht</h3>
          <p>
            Diese Webseiten dienen der Information über Lucia Ramsonius und
            ihrer Leistungen. Die Texte, Bilder, Grafiken und das Layout, sowie
            die gesamte graphische Gestaltung dieser Seiten sind
            urheberrechtlich geschützt.
          </p>
          <p>
            Sämtlicher Inhalt der Seiten darf nicht zu kommerziellen Zwecken
            kopiert, verbreitet oder verändert werden. Der Download, sowie der
            Ausdruck von Texten, Bildern und graphischen Elementen ist nur zum
            persönlichen, privaten und nicht kommerziellen Gebrauch gestattet.
            Eine Vervielfältigung von Grafiken, Bildern, Tondokumenten,
            Videosequenzen und Texten in anderen elektronischen oder gedruckten
            Publikationen ist nur nach schriftlicher Zustimmung von Lucia
            Ramsonius möglich.
          </p>
          <p>
            "Über mich" Portrait © Ludwig Schedl
          </p>
        </>
      );
    default:
      return (
        <>
          <h2>Legal Notice</h2>
          <p>
            Lucia Ramsonius
            <br />
            1230 Vienna
          </p>
          <p>
            <em>
              <Obfuscate email="lucia.ramsonius@gmail.com" />
            </em>
          </p>
          <h3>Disclaimer</h3>
          <p>
            Despite due diligence, we assume no liability for the content of
            external links. The owners of any linked pages are responsible for
            own their content.
          </p>
          <h3>Copyright</h3>
          <p>
            These web pages serve to provide information about Lucia Ramsonius
            and her services. The texts, images, graphics and layout as well as
            the entire design of these pages are protected by copyright.
          </p>
          <p>
            The content of the pages may not be copied, distributed or changed
            for commercial purposes. Downloading and printing out texts, images
            and graphic elements is only permitted for personal, private and
            non-commercial use. Duplication of graphics, images, audio files,
            video sequences and texts in other electronic or printed
            publications is only possible with the written consent of Lucia
            Ramsonius.
          </p>
          <p>
            "About me" Portrait © Ludwig Schedl
          </p>
        </>
      );
  }
}
