import { colours } from '../colours';
import { Section } from '../types';

export const getColorBySection = (section?: Section) => {
  switch (section) {
    case Section.Meditation:
      return colours.meditation;
    case Section.Qigong:
      return colours.qigong;
    case Section.Relaxer:
      return colours.relaxer;
    case Section.Yoga:
      return colours.yoga;
    default:
      return colours.about;
  }
};
