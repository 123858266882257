import React from 'react';

import { Language } from '../../types';

interface RelaxerContentProps {
  language?: Language;
}

export function RelaxerContent({ language }: RelaxerContentProps) {
  switch (language) {
    case Language.Czech:
      return (
        <>
          <h2>Relaxer technika</h2>
          <p>
          Relaxer technika je jednou z výjimečně příjemných metod uvolnění stresu, která okamžitě vyrovná a díky uvolnění hormonu oxytocinu energizuje celý nervový systém. Technika Relaxer se dvěma Relaxery, je, jako kompletní metoda pro hlavu a celé tělo, jedinečným relaxačním postupem v repertoáru každého profesionálního energetického pracovníka, terapeuta nebo zdravotní sestry. Kdokoli se navíc může naučit kratší nebo delší verzi této techniky a provádět ji kdykoli pro sebe nebo pro jinou osobu k okamžité regeneraci. Relaxačně celostní účinek je, po osvojení si postupu, okamžitý a hluboký, zejména jako prevence přepracování, stresu nebo negativních vlivů izolace. Relaxer Techniku doporučuji také jako vynikající způsob přípravy na meditaci. 
          </p>
          <h3>Účinky</h3>
          <ul>
            <li>Snižování stresu, strachu a duševního vyčerpání.</li>
            <li>Prosociální efekt bez dotyku kůže na kůži.</li>
            <li>Okamžitý tok vědomí relaxace.</li>
            <li>Aktivace systému odměňování mozku.</li>
            <li>Přirozená aktivace neurotransmiterového hormonu oxytocinu.</li>
            <li>
              Přirozená aktivace růstového hormonu na podporu posílení imunity.
            </li>
            <li>
              Aktivace energizující a harmonizující inteligence těla a mysli.
            </li>
          </ul>
        </>
      );
    case Language.German:
      return (
        <>
          <h2>Die Relaxer-Technik</h2>
          <p>
            Die Relaxer-Technik, ist eine direkte Stressabbautechnik, die sofort
            ausgleicht und über das Hormon Oxytocin das gesamte Nervensystem mit
            Energie versorgt. Die Relaxer-Technik mit zwei Relaxern ist als
            Kopf- und Ganzkörperpraxis  ein einzigartiges Entspannungstool - ein
            Highlight im Repertoire jedes professionellen Energiearbeiters,
            Therapeuten oder Gesundheitspflegers. Darüber hinaus kann jeder eine
            kürzere oder längere Version dieser Technik erlernen und sie
            jederzeit für sich selbst oder für eine andere Person als sofortige
            Regenerationstechnik durchführen. Der Entspannungseffekt ist nach
            regelmäßigem Üben unmittelbar und tiefgreifend, besonders als
            Prävention von Überlastung, Stress oder negativer Isolation.
          </p>
          <h3>Auswirkungen</h3>
          <ul>
            <li>Abbau von Stress, Angst und geistiger Erschöpfung.</li>
            <li>Prosoziale Wirkung ohne Haut-zu-Haut-Berührung.</li>
            <li>Sofortiger Fluss des Entspannungsbewusstseins.</li>
            <li>Aktivierung des Belohnungssystems des Gehirns.</li>
            <li>
              Natürliche Aktivierung des Neurotransmitterhormons Oxytocin.
            </li>
            <li>
              Natürliche Aktivierung des Wachstumshormons zur Unterstützung der
              Immunstärkung.
            </li>
            <li>
              Aktivierung der energetisierenden und harmonisierenden
              Körper-Geist-Intelligenz.
            </li>
          </ul>
        </>
      );
    default:
      return (
        <>
          <h2>Relaxer Technique</h2>
          <p>
          The Relaxer Technique is one of the most pleasant stress release techniques, which instantly balances the nervous system and energises it through very subtle strokes, causing an instant release of the hormone oxytocin. As a complete head and body practice with two Relaxers, it is a unique relaxation tool in the repertory of any professional body and energy worker, therapist or health nurse.
          </p>
          <p>
          Furthermore, anyone can learn the shorter or longer version of this technique and perform it any time needed for himself or for another person as an instant regeneration. The relaxation effect is by regular practice immediate and profound, especially in the times of overwork, stress and the negative effect of isolation. I recommend Relaxer Technique as well as a suitable preparation phase before meditation.
          </p>
          <h3>Effects</h3>
          <ul>
            <li>Reduction of stress, fear and mental exhaustion.</li>
            <li>Prosocial effect without skin to skin touch.</li>
            <li>Immediate flow of relaxation awareness.</li>
            <li>Activation of the brain’s reward system.</li>
            <li>Natural activation of neurotransmitter hormone oxytocin.</li>
            <li>
              Natural activation of growth hormone in support of immunity
              strengthening.
            </li>
            <li>
              Activation of energising and harmonising body-mind intelligence.
            </li>
          </ul>
        </>
      );
  }
}
