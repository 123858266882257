import React from 'react';

import './HeaderLayout.css';

interface HeaderLayoutProps {
  menu: React.ReactNode;
  languages: React.ReactNode;
}

export function HeaderLayout({ languages, menu }: HeaderLayoutProps) {
  return (
    <header className="lr-header">
      <div className="lr-header-menu">{menu}</div>
      <div className="lr-header-languages">{languages}</div>
    </header>
  );
}
