export enum Language {
  Czech = 'cz',
  English = 'en',
  German = 'de',
}

export enum Section {
  About = 'about',
  Contact = 'contact',
  Home = 'home',
  Impressum = 'impressum',
  Meditation = 'meditation',
  Qigong = 'qigong',
  Relaxer = 'relaxer',
  Yoga = 'yoga',
}

export type L10nProperties = {
  label: {
    [key in Language]: string;
  };
  path: {
    [key in Language]: string;
  };
  section: Section;
};

export type L10n = {
  [key in Section]: L10nProperties;
};
