import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { motion, Variants } from 'framer-motion';

import { colours } from '../../colours';
import { useHover } from '../../hooks';
import { Language, L10nProperties } from '../../types';
import { capitalizeFirstLetter, getColorBySection } from '../../utils';

import './CoverLayoutTile.css';

export type CoverLayoutTileProps = {
  imageAlt?: string;
  imageSrc?: string;
} & L10nProperties;

export function CoverLayoutTile({
  imageAlt,
  imageSrc,
  label,
  language,
  path,
  section,
  variants,
}: CoverLayoutTileProps & {
  language: Language | undefined;
  variants: Variants | undefined;
}) {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [hoverRef, isHover] = useHover(containerRef);
  const history = useHistory();
  const { pathname } = useLocation();
  const backgroundColor = getColorBySection(section);
  const formattedLabel = language ? capitalizeFirstLetter(label[language]) : '';
  const handleClick = () => {
    if (language && path[language]) {
      history.push(path[language]);
    }
  };
  return (
    <motion.div
      ref={containerRef}
      className="lr-cover-layout-tile"
      onClick={handleClick}
      variants={variants}
    >
      <img className="lr-cover-layout-image" alt={imageAlt} src={imageSrc} />
      <motion.div
        key={pathname}
        ref={hoverRef}
        style={{
          position: 'absolute',
          top: 0,
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        variants={{
          visible: {
            backgroundColor,
          },
          hidden: {
            backgroundColor: colours.transparent,
          },
        }}
        animate={{
          backgroundColor: isHover ? colours.transparent : backgroundColor,
        }}
      >
        <h2>{formattedLabel}</h2>
      </motion.div>
    </motion.div>
  );
}
