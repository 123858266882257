import { L10n, Language, Section } from './types';

export const languages = [Language.Czech, Language.English, Language.German];

export const sections = [
  Section.About,
  Section.Contact,
  Section.Home,
  Section.Impressum,
  Section.Meditation,
  Section.Qigong,
  Section.Relaxer,
  Section.Yoga,
];

export const l10n: L10n = {
  [Section.Yoga]: {
    label: {
      [Language.Czech]: 'Jóga',
      [Language.English]: 'Yoga',
      [Language.German]: 'Yoga',
    },
    path: {
      [Language.Czech]: `/${Language.Czech}/joga`,
      [Language.English]: `/${Language.English}/yoga`,
      [Language.German]: `/${Language.German}/yoga`,
    },
    section: Section.Yoga,
  },
  [Section.Qigong]: {
    label: {
      [Language.Czech]: 'Qigong',
      [Language.English]: 'Qigong',
      [Language.German]: 'Qigong',
    },
    path: {
      [Language.Czech]: `/${Language.Czech}/qigong`,
      [Language.English]: `/${Language.English}/qigong`,
      [Language.German]: `/${Language.German}/qigong`,
    },
    section: Section.Qigong,
  },
  [Section.Meditation]: {
    label: {
      [Language.Czech]: 'Meditace',
      [Language.English]: 'Meditation',
      [Language.German]: 'Meditation',
    },
    path: {
      [Language.Czech]: `/${Language.Czech}/meditace`,
      [Language.English]: `/${Language.English}/meditation`,
      [Language.German]: `/${Language.German}/meditation`,
    },
    section: Section.Meditation,
  },
  [Section.Relaxer]: {
    label: {
      [Language.Czech]: 'Relaxér',
      [Language.English]: 'Relaxer',
      [Language.German]: 'Relaxer',
    },
    path: {
      [Language.Czech]: `/${Language.Czech}/relaxer`,
      [Language.English]: `/${Language.English}/relaxer`,
      [Language.German]: `/${Language.German}/relaxer`,
    },
    section: Section.Relaxer,
  },
  [Section.About]: {
    label: {
      [Language.Czech]: 'O mně',
      [Language.English]: 'About me',
      [Language.German]: 'Über mich',
    },
    path: {
      [Language.Czech]: `/${Language.Czech}/o-mne`,
      [Language.English]: `/${Language.English}/about-me`,
      [Language.German]: `/${Language.German}/ueber-mich`,
    },
    section: Section.About,
  },
  [Section.Contact]: {
    label: {
      [Language.Czech]: 'Kontakt',
      [Language.English]: 'Contact',
      [Language.German]: 'Kontakt',
    },
    path: {
      [Language.Czech]: `/${Language.Czech}/contact`,
      [Language.English]: `/${Language.English}/kontakt`,
      [Language.German]: `/${Language.German}/kontakt`,
    },
    section: Section.Contact,
  },
  [Section.Impressum]: {
    label: {
      [Language.Czech]: 'Impressum',
      [Language.English]: 'Legal Notice',
      [Language.German]: 'Impressum',
    },
    path: {
      [Language.Czech]: `/${Language.Czech}/impressum`,
      [Language.English]: `/${Language.English}/legal-notice`,
      [Language.German]: `/${Language.German}/impressum`,
    },
    section: Section.Impressum,
  },
  [Section.Home]: {
    label: {
      [Language.Czech]: 'Home',
      [Language.English]: 'Home',
      [Language.German]: 'Home',
    },
    path: {
      [Language.Czech]: `/${Language.Czech}`,
      [Language.English]: `/${Language.English}`,
      [Language.German]: `/${Language.German}`,
    },
    section: Section.Home,
  },
};

export const sectionsByPath = {
  [l10n[Section.Yoga].path[Language.Czech]]: Section.Yoga,
  [l10n[Section.Yoga].path[Language.English]]: Section.Yoga,
  [l10n[Section.Yoga].path[Language.German]]: Section.Yoga,
  [l10n[Section.Qigong].path[Language.Czech]]: Section.Qigong,
  [l10n[Section.Qigong].path[Language.English]]: Section.Qigong,
  [l10n[Section.Qigong].path[Language.German]]: Section.Qigong,
  [l10n[Section.Meditation].path[Language.Czech]]: Section.Meditation,
  [l10n[Section.Meditation].path[Language.English]]: Section.Meditation,
  [l10n[Section.Meditation].path[Language.German]]: Section.Meditation,
  [l10n[Section.Relaxer].path[Language.Czech]]: Section.Relaxer,
  [l10n[Section.Relaxer].path[Language.English]]: Section.Relaxer,
  [l10n[Section.Relaxer].path[Language.German]]: Section.Relaxer,
  [l10n[Section.About].path[Language.Czech]]: Section.About,
  [l10n[Section.About].path[Language.English]]: Section.About,
  [l10n[Section.About].path[Language.German]]: Section.About,
  [l10n[Section.Contact].path[Language.Czech]]: Section.Contact,
  [l10n[Section.Contact].path[Language.English]]: Section.Contact,
  [l10n[Section.Contact].path[Language.German]]: Section.Contact,
  [l10n[Section.Impressum].path[Language.Czech]]: Section.Impressum,
  [l10n[Section.Impressum].path[Language.English]]: Section.Impressum,
  [l10n[Section.Impressum].path[Language.German]]: Section.Impressum,
  [l10n[Section.Home].path[Language.Czech]]: Section.Home,
  [l10n[Section.Home].path[Language.English]]: Section.Home,
  [l10n[Section.Home].path[Language.German]]: Section.Home,
};
