import React from 'react';
import { motion } from 'framer-motion';

import { Language } from '../../types';

import {
  CoverLayoutTile,
  CoverLayoutTileProps,
} from '../../components/CoverLayoutTile';

import './CoverLayout.css';
interface CoverLayoutProps {
  language: Language | undefined;
  tiles: CoverLayoutTileProps[];
}

const listVariants = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
    },
  },
};

const itemVariants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

export function CoverLayout({ language, tiles }: CoverLayoutProps) {
  return (
    <motion.div
      className="lr-cover-layout"
      initial="hidden"
      animate="visible"
      variants={listVariants}
    >
      {tiles.map((tile, index) => {
        return (
          <CoverLayoutTile
            key={index}
            language={language}
            variants={itemVariants}
            {...tile}
          />
        );
      })}
    </motion.div>
  );
}
