import React from 'react';

import { Language } from '../../types';

interface AboutContentProps {
  language?: Language;
}

export function AboutContent({ language }: AboutContentProps) {
  const thisYear = React.useMemo(() => new Date().getFullYear(), []);
  switch (language) {
    case Language.Czech:
      return (
        <>
          <h2>Lucia Ramsonius</h2>

          <p>
            <em>
              Učitelka jógy a qigongu
              <br />
              Trenérka relaxace a meditace
              <br />
              Publicistka a básnířka
            </em>
          </p>

          <h3>Styl</h3>

          <p>
            <em>
              Hatha a Flow jóga
              <br />
              Techniky uvolnění a zklidnění mysli
              <br />
              Úvod a mentoring v meditační praxi (různé styly)
              <br />
              Vlastní Relaxer technika
            </em>
          </p>

          <h3>Vzdělání</h3>

          <p>
            Taneční vědu jsem studovala v letech 1986-90 na Hudební akademii
            umění v Praze. Zároveň jsem působila jako tanečnice ve VUS UK
            (Vysokoškolský umělecký soubor UK, Praha). V letech 1989/90 jsem
            byla na stáži jako student-pozorovatel v tanečním divadle Nederlands
            Dance Theater, v Den Haagu.
          </p>
          <p>
            Od roku 1991 jsem začala studovat a praktikovat Bhakti jógu při Šrí
            Čaitanya Saraswat Mathu a pokračuji při Jiva Institutu v Indii pod
            vedením Satyanarayana Dase Babajiho.
          </p>
          <p>
            V letech 2001-03 jsem studovala Hatha jógu na dánském institutu
            Humanum Factum.
          </p>
          <p>
            Jsem certifikovaná trenérka relaxace, jógy a qigongu (PFA Academy
            Vienna, 2015/16), s dalšími diplomy Pránického léčení and Arhatické
            Jógy (Institute of Pranic Healing, Vídeň, 2011), Masérka pro sport a
            regenerační masáže (Centrum regeneračních masáží, služby a
            vzdělávání, Praha, 2009), certifikovaná praktikantka Raindrop
            Techniky (Akademie aromaterapie, Vídeň, 2006) a techniky Amma
            Shiatsu (Massage Institute, Brno, 2006). V roce 2008 jsem vyvinula
            vlastní „Relaxer Technique“, kterou nyní dále učím.
          </p>

          <h3>Projekty, práce a zkušenosti</h3>

          <ul className="lr-content-layout-cv">
            <li>
              <span>1997-2019</span>
              <span>
                Vlastní taneční choreografie a představení „Taneční miniatury“
                DK, Rakousko, CZ
              </span>
            </li>
            <li>
              <span>2000-2003</span>
              <span>
                Učitelka a choreografka tanečního projektu: „Dans i Uddanelse“,
                DK, Dance in Education
              </span>
            </li>
            <li>
              <span>2004</span>
              <span>
                Dvě taneční choreografie pro školní akademii ve Schwechatu v
                Rakousku
              </span>
            </li>
            <li>
              <span>2004-{thisYear}</span>
              <span>
                Učitelka jógy a qigongu v mnoha vídeňských studiích wellness a
                jógy
              </span>
            </li>
            <li>
              <span>2004-2013</span>
              <span>
                Organizátorka a učitelka sedmi týdenních seminářů jógy a qigongu
                v českém Iyengar Jóga Centru v Hlavici
              </span>
            </li>
            <li>
              <span>2018-2019</span>
              <span>Manager Asistent na Vídeňské akademii vizionářského umění</span>
            </li>
          </ul>

          <h3>Publikace a články</h3>

          <ul className="lr-content-layout-cv">
            <li>
              <span>1987-1989</span>
              <span>Časopis Mladý svět, články o moderním tanci</span>
            </li>
            <li>
              <span>1988</span>
              <span>Časopis Scéna, článek: Choreograf Jiří Kylián</span>
            </li>
            <li>
              <span>1990-2009</span>
              <span>
                Vydavatelství Ambrosia, překlady filosofické literatury a
                tlumočení
              </span>
            </li>
            <li>
              <span>2006-{thisYear}</span>
              <span>Články a básně ve VSL, Vídeňské svobodné listy</span>
            </li>
            <li>
              <span>2009-{thisYear}</span>
              <span>Vydání každoroční sbírky básní (dosud jedenáct sbírek)</span>
            </li>
          </ul>
        </>
      );
    case Language.German:
      return (
        <>
          <h2>Lucia Ramsonius</h2>

          <p>
            <em>
              Yoga- und Qigong-Lehrerin
              <br />
              Entspannungs- und Meditationstrainerin
              <br />
              Kolumnistin und Dichterin
            </em>
          </p>

          <h3>Stil</h3>

          <p>
            <em>
              Hatha- und Flow-Yoga
              <br />
              Entspannungs- und Beruhigungstechniken des Geistes
              <br />
              Einführung und Mentoring in die Meditationspraxis (verschiedene
              Stile)
              <br />
              Eigene Relaxer-Technik
            </em>
          </p>

          <h3>Ausbildung</h3>

          <p>
            Ich habe von 1986-90 Tanzwissenschaften an der Musikakademie in Prag
            studiert. Gleichzeitig war ich Tänzerin bei der VUS UK (Charles
            University Dance Company, Prag). Ich asolvierte 1989/90 ein
            Praktikum als studentische Beobachterin am Nederland Dance Theatre,
            Den Haag.
          </p>
          <p>
            Seit 1991 habe ich mein Studium und meine Praxis von Bhakti Yoga bei
            Shri Chaitanya Saraswat Math begonnen und führe in Indien am Jiva
            Institute unter Satyanarayan Das Babaji fort. Von 2001-03 habe ich
            Hatha Yoga am dänischen Institut Humanum Factum studiert.
          </p>
          <p>
            Ich bin zertifizierte Entspannungstechnik-, Yoga- und
            Qigongtrainerin (PFA Akademie Wien, 2015/16), mit Diplomen für
            Pranic Healing und Arhatic Yoga (Institut für Pranic Healing, Wien,
            2011), Masseurin für Sport und regenerative Massagen (Centre for
            regenerative services and education, Prag, 2009), zertifizierter
            Raindrop Technique Practitioner (Akademie für Aromatherapie, Wien,
            2006) und Amma Shiatsu Technique (Massage Institute, Brno, 2006).
            2008 habe ich meine eigene "Relaxer-Technik" entwickelt, die ich
            jetzt weiter unterrichte.
          </p>
          <h3>Projekte, Arbeit und Erfahrung</h3>

          <ul className="lr-content-layout-cv">
            <li>
              <span>1997-2019</span>
              <span>
                Eigene Tanzchoreografien und Performances "Tanzminiaturen" DK,
                Österreich, CZ
              </span>
            </li>
            <li>
              <span>2000-2003</span>
              <span>
                Lehrer und Choreograf beim Tanzprojekt: "Dans i Uddanelse", DK,
                Dance in Education
              </span>
            </li>
            <li>
              <span>2004</span>
              <span>
                Zwei Tanzchoreografien für die Schulakademie in Schwechat,
                Österreich
              </span>
            </li>
            <li>
              <span>2004-{thisYear}</span>
              <span>
                Yoga- und Qigong-Lehrerin in vielen Wiener Wellness- und
                Yoga-Studios
              </span>
            </li>
            <li>
              <span>2004-2013</span>
              <span>
                Organisatorin und Lehrerin von 7 wöchigen Sommer-Yoga- und
                Qigong-Seminaren im Iyengar Yoga Center in Hlavice, CZ
              </span>
            </li>
            <li>
              <span>2018-2019</span>
              <span>
                Assistentin an der Wiener Akademie für Visionäre Kunst
              </span>
            </li>
          </ul>

          <h3>Publikationen und Artikel</h3>

          <ul className="lr-content-layout-cv">
            <li>
              <span>1987-1989</span>
              <span>
                Zeitschrift Mladý svět (Junge Welt) Artikel über modernen Tanz
              </span>
            </li>
            <li>
              <span>1988</span>
              <span>Journal Scéna, Artikel: Choreograf Jiří Kylián</span>
            </li>
            <li>
              <span>1990-2009</span>
              <span>
                Veröffentlichung von Ambrosia, Übersetzungen und philosophischer
                Literatur und Dolmetschen
              </span>
            </li>
            <li>
              <span>2006-{thisYear}</span>
              <span>Artikel und Gedichte in VSL, Die Wiener Freibriefe</span>
            </li>
            <li>
              <span>2009-{thisYear}</span>
              <span>Veröffentlichung eines jährlichen Gedichtstraußes</span>
            </li>
          </ul>
        </>
      );
    default:
      return (
        <>
          <h2>Lucia Ramsonius</h2>

          <p>
            <em>
              Yoga and Qigong Teacher
              <br />
              Relaxation and Meditation Trainer
              <br />
              Columnist and Poet
            </em>
          </p>

          <h3>Style</h3>

          <p>
            <em>
              Hatha and Flow Yoga
              <br />
              Techniques to relax and calm the mind
              <br />
              Introduction and mentoring in meditation praxis (different styles)
              <br />
              Own Relaxer Technique
            </em>
          </p>

          <h3>Education</h3>

          <p>
            I studied Dance Research from 1986-90 at the Musical Academy of Arts
            in Prague. At the same time I was a dancer at the VUS UK (Charles
            University Dance Company, Prague). I was at the internship in
            1989/90 as a student observer at the Nederland Dance Theatre, Den
            Haag. Since 1991 I began my study and practice of Bhakti Yoga at
            Shri Chaitanya Saraswat Math, and I continue at the Jiva Institute,
            India under Satyanarayan Das Babaji. From 2001-03 I also studied
            Hatha Yoga at the Danish institute Humanum Factum.
          </p>
          <p>
            I am a certified Relaxation Technique, Yoga and Qigong Trainer (PFA
            Academy Vienna, 2015/16), with diplomas from Pranic Healing and
            Arhatic Yoga (Institute of Pranic Healing, Vienna, 2011), Masseur
            for Sport and Regenerative Massages (Center for regenerative
            services and education, Prague, 2009), certified Raindrop Technique
            Practitioner (Academy of Aromatherapy, Vienna, 2006) and Amma
            Shiatsu Technique (Massage Institute, Brno, 2006). In 2008 I
            developed my own "Relaxer Technique", which I now further teach.
          </p>

          <h3>Projects, Work and Experience</h3>

          <ul className="lr-content-layout-cv">
            <li>
              <span>1997-2019</span>
              <span>
                Own dance choreographies and performances "Dance miniatures" DK,
                Austria, CZ
              </span>
            </li>
            <li>
              <span>2000-2003</span>
              <span>
                Teacher and choreographer at the dance project: "Dans i
                Uddanelse", DK, Dance in Education
              </span>
            </li>
            <li>
              <span>2004</span>
              <span>
                Two dance choreographies for school academy in Schwechat,
                Austria
              </span>
            </li>
            <li>
              <span>2004-{thisYear}</span>
              <span>
                Yoga and Qigong teacher at many Viennese Wellness and Yoga
                studios
              </span>
            </li>
            <li>
              <span>2004-2013</span>
              <span>
                Organiser and teacher at seven week summer yoga and qigong
                seminars at the Iyengar Yoga Center at Hlavice, CZ
              </span>
            </li>
            <li>
              <span>2018-2019</span>
              <span>Assistant at The Vienna Academy of Visionary Art</span>
            </li>
          </ul>

          <h3>Publications and Articles</h3>

          <ul className="lr-content-layout-cv">
            <li>
              <span>1987-1989</span>
              <span>
                Journal Mladý svět (Young World) Articles on modern Dance
              </span>
            </li>
            <li>
              <span>1988</span>
              <span>Journal Scéna, article: Choreographer Jiří Kylián</span>
            </li>
            <li>
              <span>1990-2009</span>
              <span>
                Publishing Ambrosia, translations of philosophical literature
                and interpreting
              </span>
            </li>
            <li>
              <span>2006-{thisYear}</span>
              <span>Articles and poems in VSL, The Viennese Free Epistles</span>
            </li>
            <li>
              <span>2009-{thisYear}</span>
              <span>
                Publication of a yearly bouquet of poems (so far eleven bouquets
                published)
              </span>
            </li>
          </ul>
        </>
      );
  }
}
