import React from 'react';
import Obfuscate from 'react-obfuscate';

import { Language } from '../../types';

interface ContactContentProps {
  language?: Language;
}

export function ContactContent({ language }: ContactContentProps) {
  switch (language) {
    case Language.Czech:
      return (
        <>
          <h2>Kontakt</h2>
          <p>
            Neváhejte mě kontaktovat e-mailem a zaregistrovat se na kurzy nebo
            na jakékoli dotazy. Letos v létě budu učit jógu a qigong venku v
            některých vídeňských parcích.
          </p>
          <h3>
            <Obfuscate email="lucia.ramsonius@gmail.com" />
          </h3>
        </>
      );
    case Language.German:
      return (
        <>
          <h2>Kontakt</h2>
          <p>
            Kontaktieren Sie mich gerne per E-Mail, um sich für Kurse anzumelden
            oder für Fragen. Diesen Sommer werde ich Yoga und Qigong im Freien
            in den Wiener Parks unterrichten.
          </p>
          <h3>
            <Obfuscate email="lucia.ramsonius@gmail.com" />
          </h3>
        </>
      );
    default:
      return (
        <>
          <h2>Contact</h2>
          <p>
            Feel free to contact me by email to register for classes or for any
            questions you may have. This summer I will be teaching yoga and
            qigong outdoors in some of Vienna’s parks.
          </p>
          <h3>
            <Obfuscate email="lucia.ramsonius@gmail.com" />
          </h3>
        </>
      );
  }
}
