import React from 'react';

import { Language } from '../../types';

interface MeditationContentProps {
  language?: Language;
}

export function MeditationContent({ language }: MeditationContentProps) {
  switch (language) {
    case Language.Czech:
      return (
        <>
          <h2>Meditace</h2>
          <p>
            Meditace je vnitřní krása malých kroků sebekultivace. Je to
            schopnost vystoupit z našeho momentálního myšlení, nebo jej změnit.
            Vyžaduje pravidelné cvičení, které nemusí nutně trvat dlouhou dobu.
          </p>
          <p>
            Někteří cvičí 7 minut denně - ale každý den. Jiní začínají jednou
            minutou, a později se věnují půlhodinové meditaci každé dva dny,
            zatímco jiní meditují pod vedením jednu hodinu týdně. Chcete-li
            najít svůj vlastní prostor, čas a způsob meditování, který není
            statickým, ale dynamickým procesem, vyzývám vás, abyste toto pole
            prozkoumali. Podělím se ráda o své postřehy, které Vám pomohou najít
            Váš meditativní způsob a praxi.
          </p>
          <p>
            37 let praktikuji různé typy meditačních technik a přístupů pod
            vedením různých učitelů jógy. V roce 2011 jsem absolvovala
            certifikovaný kurz Institutu pránického léčení a arhatické jógy,
            dále certifikovaný kurz PFA - Výcvik relaxačních technik v letech
            2015/16, a od roku 2013 se učím dále při Institutu Jiva.
          </p>
        </>
      );
    case Language.German:
      return (
        <>
          <h2>Meditation</h2>
          <p>
            Meditation ist innere Schönheit in Form von wohl dosierter
            Selbstversorge. Die Fähigkeit aus unserer momentanen Denkweise
            herauszutreten oder sie zu ändern, bedarf einer regelmäßigen Übung,
            die nicht unbedingt lange dauern muss. Manche üben 7 Minuten am Tag
            – jeden Tag. Andere beginnen mit einer Minute und machen später alle
            zwei Tage eine halbe Stunde Meditation, während andere eine Stunde
            pro Woche unter Anleitung meditieren. Um Ihren eigenen Raum, Ihre
            Zeit und einen Weg der Praxis zu finden, der kein statischer,
            sondern ein dynamischer Prozess ist, lade ich Sie ein dieses Feld zu
            erkunden, und ich werde meine Erkenntnisse teilen, um Ihnen zu
            helfen, Ihren meditativen Weg und Ihre Praxis zu finden.
          </p>
          <p>
            Seit 37 Jahren praktiziere ich unter Anleitung verschiedener
            Yogalehrer verschiedene Arten von Meditationstechniken und
            -ansätzen. Ich habe auch einen zertifizierten Kurs vom Institut für
            Pranic Healing und Arhatic Yoga im Jahr 2011 und einen
            zertifizierten PFA-Kurs für Entspannungstechniken Training im Jahr
            2015/16 absolviert und mache weiterhin Fortbildungen am Jiva
            Institut.
          </p>
        </>
      );
    default:
      return (
        <>
          <h2>Meditation</h2>
          <p>
            Meditation is inner beauty of small steps of self cultivation. To be
            able to step out of our momentarily mindset or to change it, needs a
            regular practice, which may not necessarily need a long time. Some
            practice 7 minutes a day, but every day, while others start with one
            minute and proceed to do half an hour of meditation every two days.
            Some even meditate under the guidance one hour a week. To find your
            own space, time and a way of practice, which is not a static, but a
            dynamic process, I invite you to explore this field, and I will
            share my insights to help you to find your meditative way and
            practice.
          </p>
          <p>
            For 37 years I have practiced different types of meditation
            techniques and approaches under the guidance of various yoga
            teachers. I also took a certified course from the Institute of
            Pranic Healing and Arhatic Yoga in 2011 a certified PFA course of
            Relaxation techniques Training in 2015/16, and I continue to study
            with the Jiva Institute since 2013.
          </p>
        </>
      );
  }
}
