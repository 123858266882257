import { useEffect, useState } from 'react';

import { Section } from '../types';

import { useSection } from './useSection';

export function useMenuItemIsActive(section: Section) {
  const [menuItemIsActive, setMenuItemIsActive] = useState<boolean>(false);
  const currentSection = useSection();
  useEffect(() => {
    if (currentSection && section === currentSection) {
      setMenuItemIsActive(true);
    } else {
      setMenuItemIsActive(false);
    }
  }, [section, currentSection]);
  return menuItemIsActive;
}
