import React from 'react';

import { Language } from '../../types';
interface QigongContentProps {
  language?: Language;
}

export function QigongContent({ language }: QigongContentProps) {
  switch (language) {
    case Language.Czech:
      return (
        <>
          <h2>Qigong</h2>
          <p>
            Na semináři francouzského učitele a choreografa Herve Diasnase jsem
            jako studentka poprvé zažila plynulé pohyby qigongu. To mne hned
            fascinovalo, a já věděla, že je to něco pro mne.
          </p>
          <p>
            Qigong je poezie těla a mysli - meditace v pohybu. V qigongu se
            pohybujeme především pomaleji a uvolněněji, což nám umožňuje otevřít
            důležité energetické dráhy našeho těla. Naučíme se cítit naši
            energii a komunikovat s oblastmi, které vyžadují zvýšenou pozornost
            a péči.
          </p>
          <p>
            Otevíráme zúžení například rytmickými vzory klepání, třesení nebo
            jemnými hladivými pohyby uklidňující kvality jin. Prostřednictvím
            kůže aktivujeme růstové hormony, které v dospělosti stimulují náš
            imunitní systém. Takzvaný tichý qigong dává prožitek meditativního
            ticha - vlastního vnitřního míru se smyslem pro rovnováhu, sílu a
            soustředění.
          </p>
          <p>
            Vnitřní úsměv nám přirozeným způsobem připomíná zdroj radosti ze
            života, starodávnou praxi, která nás spojuje s moudrostí našeho
            bytí. Qigong může být optimálně praktikován v přírodě.
          </p>
          <p>
            Jaké jsou zdravotní výhody qigongu? Lidé cvičí qigong z mnoha
            různých důvodů, včetně rekreace, pohybu a relaxace, jako meditaci a
            sebekultivaci nebo jako průpravu k bojovým uměním. Unikátní
            univerzální poselství qigongu vyhovuje všem praktikujícím, od
            sportovců po rekonvalescenty. Tato starodávná praxe, zakořeněná v
            tradiční čínské medicíně (TCM), může snížit hladinu stresu a pomoci
            vašemu tělu optimálně fungovat.
          </p>
        </>
      );
    case Language.German:
      return (
        <>
          <h2>Qigong</h2>
          <p>
            Auf einem Seminar des französischen Lehrers und Choreographen Herve
            Diasnas habe ich als junge Studentin zum ersten Mal die fließenden
            Bewegungen im Qigong erlebt. Es hat mich gleich fasziniert, und ich
            wusste, das ist etwas für mich.
          </p>
          <p>
            Qigong kann als Poesie von Körper und Geist oder Meditation in
            Bewegung verstanden werden. In Qigong bewegen wir uns hauptsächlich
            in einem langsamen und entspannenten Fluss, der es uns ermöglicht
            lebenswichtige Energielinien unseres Körpers zu öffnen. Wir lernen
            unsere Energie zu spüren und mit den Körperteilen zu kommunizieren,
            die Aufmerksamkeit und Pflege benötigen.
          </p>
          <p>
            Wir öffnen Verengungen zum Beispiel durch verschiedene rhythmische
            Aktivierungen wie Klopfen, Schütteln oder durch sanfte
            Jin-Massage-Bewegungen. So aktivieren wir durch die Haut
            Wachstumshormone, die im Erwachsenenalter unser Immunsystem anregen.
          </p>
          <p>
            Das so genannte stille Qigong gibt ein Gefühl meditativer Stille,
            stellt den eigenen inneren Frieden mit einem Gefühl für inneres
            Gleichgewicht, Stärke und Fokus her. Das innere Lächeln erinnert uns
            auf natürliche Weise, an die Quelle der Lebensfreude der alten
            Praxis, die uns mit der Weisheit unseres Seins verbindet. Qigong
            eignet sich auch sehr gut für das Praktizieren in der Natur.
          </p>
          <p>
            Was sind die gesundheitlichen Vorteile von Qigong? Menschen
            praktizieren Qigong aus vielen Gründen wie zur Erholung, Bewegung
            und Entspannung, als Meditation und Selbstfürsorge oder als Training
            für Kampfkunst. Die einzigartige universelle Botschaft von Qigong
            spricht alle Praktizierenden an. Dies können verschiedenste
            interessierte Personen sein, die von Genesenden bis zu Sportlern
            reichen. Diese alte Praxis, die in der traditionellen chinesischen
            Medizin (TCM) verwurzelt ist, kann Stress reduzieren und Ihren
            Körper unterstützen, optimal zu funktionieren.
          </p>
        </>
      );
    default:
      return (
        <>
          <h2>Qigong</h2>
          <p>
            As a student I experienced the flowing movements of Qigong for the
            first time at the workshop of a French teacher and choreographer
            Herve Diasnas. It instantly fascinated me, and I knew that it was
            something for me.
          </p>
          <p>
            Qigong is poetry of body and mind and meditation in movement. In
            Qigong we move mostly in a slower relaxing flow, which allows in a
            pleasant noninvasive way to open up vital energy lines of our body.
            We learn to sense the energy, and communicate with the parts that
            need attention and care. We open tight spaces through different
            rhythmical patterns of tapping, shaking or through smooth jin
            massage movements. In this way, through the skin, we activate growth
            hormones, which in adulthood boost our immune system.
          </p>
          <p>
            The so-called silent Qigong gives one a sensation of meditative
            silence, uncovering one’s own inner peace, with a sense for balance
            and focus. Inner smile reminds us in a natural way of the source of
            joy in life. Qigong is an ancient practice that connects us with the
            wisdom of our being.
          </p>
          <p>
            What are the health benefits of Qigong? People practice it for many
            reasons, including for recreation, exercise and relaxation, as a
            preventive medicine and self-healing, as well as for meditation and
            self-cultivation, or training for martial arts. The unique universal
            message of Qigong speaks to all practitioners. They range from
            athletes to the convalescents. This ancient practice, rooted in
            Traditional Chinese Medicine (TCM) can reduce stress and help your
            body and mind function at its best.
          </p>
        </>
      );
  }
}