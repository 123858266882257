import * as React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { LanguagePicker, NavList } from '../../components';

import {
  AboutContent,
  ContactContent,
  ImpressumContent,
  MeditationContent,
  QigongContent,
  RelaxerContent,
  YogaContent,
} from '../../contents';

import {
  AppLayout,
  BodyLayout,
  ContentLayout,
  CoverLayout,
  FooterLayout,
  HeaderLayout,
} from '../../layouts';

import { languages } from '../../hooks';
import { l10n } from '../../settings';
import { Language, Section } from '../../types';
import { capitalizeFirstLetter } from '../../utils';
import { useLanguage } from '../../hooks/useLanguage';
import { useWindowSize } from '../../hooks/useWindowSize';

import AboutPortraitSrc from '../../assets/img/lr-about-portrait.jpg';
import HomePortraitSrc from '../../assets/img/lr-home-portrait.jpg';
import ImpressumPortraitSrc from '../../assets/img/lr-impressum-portrait.jpg';
import MeditationPortraitSrc from '../../assets/img/lr-meditation-portrait.jpg';
import QigongPortraitSrc from '../../assets/img/lr-qigong-portrait.jpg';
import RelaxerPortraitSrc from '../../assets/img/lr-relaxer-portrait.jpg';
import YogaPortraitSrc from '../../assets/img/lr-yoga-portrait.jpg';

import AboutSquareSrc from '../../assets/img/lr-about-square.jpg';
import HomeSquareSrc from '../../assets/img/lr-home-square.jpg';
import ImpressumSquareSrc from '../../assets/img/lr-impressum-square.jpg';
import MeditationSquareSrc from '../../assets/img/lr-meditation-square.jpg';
import QigongSquareSrc from '../../assets/img/lr-qigong-square.jpg';
import RelaxerSquareSrc from '../../assets/img/lr-relaxer-square.jpg';
import YogaSquareSrc from '../../assets/img/lr-yoga-square.jpg';

import MeditationThumbnailSrc from '../../assets/img/lr-meditation-thumbnail.jpg';
import QigongThumbnailSrc from '../../assets/img/lr-qigong-thumbnail.jpg';
import RelaxerThumbnailSrc from '../../assets/img/lr-relaxer-thumbnail.jpg';
import YogaThumbnailSrc from '../../assets/img/lr-yoga-thumbnail.jpg';

export function AppContainer() {
  const [language, setLanguage] = useLanguage();
  const { width: windowWidth } = useWindowSize();
  return (
    <AppLayout
      header={
        <HeaderLayout
          menu={
            <NavList
              language={language}
              items={[
                l10n[Section.Yoga],
                l10n[Section.Qigong],
                l10n[Section.Meditation],
                l10n[Section.Relaxer],
              ]}
              showLogo={true}
            />
          }
          languages={
            <LanguagePicker
              language={language}
              items={languages}
              onSelect={setLanguage}
            />
          }
        />
      }
      body={
        <BodyLayout
          left={<div />}
          center={
            <Switch>
              <Route
                path={[
                  l10n[Section.Yoga].path[Language.Czech],
                  l10n[Section.Yoga].path[Language.English],
                  l10n[Section.Yoga].path[Language.German],
                ]}
              >
                <ContentLayout
                  section={Section.Yoga}
                  text={<YogaContent language={language} />}
                  imageAlt={capitalizeFirstLetter(
                    l10n[Section.Yoga].label[language],
                  )}
                  imageSrc={
                    windowWidth && windowWidth < 1200
                      ? YogaSquareSrc
                      : YogaPortraitSrc
                  }
                />
              </Route>
              <Route
                path={[
                  l10n[Section.Qigong].path[Language.Czech],
                  l10n[Section.Qigong].path[Language.English],
                  l10n[Section.Qigong].path[Language.German],
                ]}
              >
                <ContentLayout
                  section={Section.Qigong}
                  text={<QigongContent language={language} />}
                  imageAlt={capitalizeFirstLetter(
                    l10n[Section.Qigong].label[language],
                  )}
                  imageSrc={
                    windowWidth && windowWidth < 1200
                      ? QigongSquareSrc
                      : QigongPortraitSrc
                  }
                />
              </Route>
              <Route
                path={[
                  l10n[Section.Meditation].path[Language.Czech],
                  l10n[Section.Meditation].path[Language.English],
                  l10n[Section.Meditation].path[Language.German],
                ]}
              >
                <ContentLayout
                  section={Section.Meditation}
                  text={<MeditationContent language={language} />}
                  imageAlt={capitalizeFirstLetter(
                    l10n[Section.Meditation].label[language],
                  )}
                  imageSrc={
                    windowWidth && windowWidth < 1200
                      ? MeditationSquareSrc
                      : MeditationPortraitSrc
                  }
                  invertOrder={true}
                />
              </Route>
              <Route
                path={[
                  l10n[Section.Relaxer].path[Language.Czech],
                  l10n[Section.Relaxer].path[Language.English],
                  l10n[Section.Relaxer].path[Language.German],
                ]}
              >
                <ContentLayout
                  section={Section.Relaxer}
                  text={<RelaxerContent language={language} />}
                  imageAlt={capitalizeFirstLetter(
                    l10n[Section.Relaxer].label[language],
                  )}
                  imageSrc={
                    windowWidth && windowWidth < 1200
                      ? RelaxerSquareSrc
                      : RelaxerPortraitSrc
                  }
                  invertOrder={true}
                />
              </Route>
              <Route
                path={[
                  l10n[Section.About].path[Language.Czech],
                  l10n[Section.About].path[Language.English],
                  l10n[Section.About].path[Language.German],
                ]}
              >
                <ContentLayout
                  section={Section.About}
                  text={<AboutContent language={language} />}
                  imageAlt={capitalizeFirstLetter(
                    l10n[Section.About].label[language],
                  )}
                  imageSrc={
                    windowWidth && windowWidth < 1200
                      ? AboutSquareSrc
                      : AboutPortraitSrc
                  }
                />
              </Route>
              <Route
                path={[
                  l10n[Section.Contact].path[Language.Czech],
                  l10n[Section.Contact].path[Language.English],
                  l10n[Section.Contact].path[Language.German],
                ]}
              >
                <ContentLayout
                  section={Section.Contact}
                  text={<ContactContent language={language} />}
                  imageAlt={capitalizeFirstLetter(
                    l10n[Section.Contact].label[language],
                  )}
                  imageSrc={
                    windowWidth && windowWidth < 1200
                      ? HomeSquareSrc
                      : HomePortraitSrc
                  }
                />
              </Route>
              <Route
                path={[
                  l10n[Section.Impressum].path[Language.Czech],
                  l10n[Section.Impressum].path[Language.English],
                  l10n[Section.Impressum].path[Language.German],
                ]}
              >
                <ContentLayout
                  section={Section.Impressum}
                  text={<ImpressumContent language={language} />}
                  imageAlt={capitalizeFirstLetter(
                    l10n[Section.Impressum].label[language],
                  )}
                  imageSrc={
                    windowWidth && windowWidth < 1200
                      ? ImpressumSquareSrc
                      : ImpressumPortraitSrc
                  }
                />
              </Route>
              <Route
                path={[
                  l10n[Section.Home].path[Language.Czech],
                  l10n[Section.Home].path[Language.English],
                  l10n[Section.Home].path[Language.German],
                ]}
              >
                <CoverLayout
                  tiles={[
                    {
                      ...l10n[Section.Yoga],
                      imageAlt: capitalizeFirstLetter(
                        l10n[Section.Yoga].label[language],
                      ),
                      imageSrc: YogaThumbnailSrc,
                    },
                    {
                      ...l10n[Section.Qigong],
                      imageAlt: capitalizeFirstLetter(
                        l10n[Section.Qigong].label[language],
                      ),
                      imageSrc: QigongThumbnailSrc,
                    },
                    {
                      ...l10n[Section.Meditation],
                      imageAlt: capitalizeFirstLetter(
                        l10n[Section.Meditation].label[language],
                      ),
                      imageSrc: MeditationThumbnailSrc,
                    },
                    {
                      ...l10n[Section.Relaxer],
                      imageAlt: capitalizeFirstLetter(
                        l10n[Section.Relaxer].label[language],
                      ),
                      imageSrc: RelaxerThumbnailSrc,
                    },
                  ]}
                  language={language}
                />
              </Route>
              <Route path="*">
                <Redirect to={`/${language}`} />
              </Route>
            </Switch>
          }
          right={<div />}
        />
      }
      footer={
        <FooterLayout
          menu={
            <NavList
              language={language}
              items={[
                l10n[Section.About],
                l10n[Section.Contact],
                l10n[Section.Impressum],
              ]}
            />
          }
          copyright={
            windowWidth && windowWidth > 500
              ? 'Lucia Ramsonius © 2021'
              : 'L.R. © 2021'
          }
        />
      }
    />
  );
}
