import {
  useEffect,
  useRef,
  useState,
  MutableRefObject,
  useImperativeHandle,
} from 'react';

export function useHover<T extends HTMLElement = HTMLElement>(
  parentRef:
    | ((instance: T | null) => void)
    | MutableRefObject<T | null>
    | null = null,
  mode: 'enter' | 'over' = 'enter',
): [MutableRefObject<T>, boolean] {
  const [hover, setHover] = useState<boolean>(false);
  const ref = useRef<T>(null);
  useImperativeHandle(parentRef, () => ref.current as T, []);
  useEffect(() => {
    const node = ref.current;
    const handleHoverStart = () => setHover(true);
    const handleHoverEnd = () => setHover(false);
    if (node) {
      node.addEventListener(`mouse${mode}`, handleHoverStart);
      node.addEventListener('mouseleave', handleHoverEnd);
      return () => {
        node.removeEventListener(`mouse${mode}`, handleHoverStart);
        node.removeEventListener('mouseleave', handleHoverEnd);
      };
    }
    return undefined;
  }, [mode]);
  return [ref as MutableRefObject<T>, !!hover];
}
